<template>
  <div class="Template" v-loading="loading">
    <div class="list-header">
      <div class="header-left">
        <div>{{$t('lang.QText90')}}</div>
        <div class="number">{{ listData.length }}/10</div>
      </div>
      <div class="header-right">
        <el-button @click="back()">{{$t('lang.BText35')}}</el-button>
        <el-button @click="clickDraft()">{{$t('lang.BText6')}}</el-button>
        <el-button class="create" @click="createBooking()"
          >+ {{$t('lang.BText34')}}</el-button
        >
      </div>
    </div>
    <dwlist
      :url="source.booking_api.templateList"
      :query-params="temQueryForm"
      cellClassName="fillRow"
      :pagination="false"
      ref="list"
      @onloadSuccess="onloadSuccess"
    >
      <el-table-column type="index" :label="$t('lang.QText91')" width="80" :index="indexMethod">
      </el-table-column>
      <el-table-column prop="templateName" :label="$t('lang.QText92')">
      </el-table-column>
      <el-table-column prop="Summry" :label="$t('lang.BText4')" width="350">
        <template slot-scope="scope">
          <div class="port">
            {{ scope.row.originPortName
            }}<svg-icon icon-class="right" style="color: #299be4"></svg-icon
            >{{ scope.row.destinationDischargePortName }}
          </div>
          <div v-if="scope.row.containerType">
            <div
              v-for="(item, index) in JSON.parse(scope.row.containerType)"
              :key="index"
            >
              {{ item.containerTypOne }}*{{ item.containerTypTwo }}
            </div>
          </div>
          <!-- <div v-else class="emptySlot">Empty slot</div> -->
        </template>
      </el-table-column>
      <el-table-column prop="Type" :label="$t('lang.BText8')" width="100">
        <template slot-scope="scope">
          <div class="FCL" v-show="scope.row.bookingType == 'FCL'">
            <svg-icon class="typeIcon" icon-class="oceanFreight"></svg-icon>
            <div class="line"></div>
            <svg-icon class="typeIcon" icon-class="FCL"></svg-icon>
          </div>
          <div class="LCL" v-show="scope.row.bookingType == 'LCL'">
            <svg-icon class="typeIcon" icon-class="oceanFreight"></svg-icon>
            <div class="line"></div>
            <svg-icon class="typeIcon" icon-class="LCL"></svg-icon>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="shipper" :label="$t('lang.BText17')"> </el-table-column>
      <el-table-column prop="consignee" :label="$t('lang.BText3')"> </el-table-column>
      <el-table-column prop="updateDate" :label="$t('lang.BText38')"> </el-table-column>
      <el-table-column prop="createBy" :label="$t('lang.BText39')"> </el-table-column>
      <el-table-column fixed="right" width="400px">
        <template slot-scope="scope" v-if="scope.row.updateDate">
          <el-button @click="clickRename(scope.row)" class="samll-list-info"
            >{{$t('lang.BText55')}}</el-button
          >
          <el-button @click="clickDelete(scope.row)" class="samll-list-info"
            >{{$t('lang.BText40')}}</el-button
          >
          <el-button class="samll-list-primary" @click="Booking(scope.row)"
            >{{$t('lang.BText35')}}</el-button
          >
        </template>
      </el-table-column>
    </dwlist>
    <dw-check-box
      :showCheckBox="showDelect"
      @Cancel="Cancel"
      @Save="Delete"
      :CancelButton="$t('lang.QText79')"
      :primaryButton="$t('lang.BText40')"
    >
      {{$t('lang.BText99')}}
    </dw-check-box>
    <dw-check-box
      :showCheckBox="showRename"
      @Cancel="Cancel"
      @Save="Rename"
      :CancelButton="$t('lang.QText79')"
      :primaryButton="$t('lang.SText13')"
    >
      {{$t('lang.QText92')}} *
      <el-input class="nameInput" v-model="form.templateName"></el-input>
    </dw-check-box>
  </div>
</template>

<script>
import dwlist from "../../components/dwList/dwlist.vue";
import dwCheckBox from "../../components/dwCheckBox/dwCheckBox.vue";
import test from "../../test/test";
export default {
  name: "bookingTemplate",
  components: {
    dwlist,
    dwCheckBox,
  },
  data() {
    return {
      listData: [],
      showDelect: false,
      showRename: false,
      temQueryForm: {},
      form: {},
      loading: false,
    };
  },
  created() {
    this.$session("userInfo").get((value) => {
      this.temQueryForm.companyId = value.companyid;
    });
  },
  methods: {
    indexMethod(index) {
      if (index < 9) {
        return "0" + (index + 1);
      }
      return index + 1;
    },
    query() {
      this.$refs.list.query();
    },
    createBooking() {
      this.$router.push({ name: "Booking", params: { createFlag: true } });
    },
    clickDelete(data) {
      this.showDelect = true;
      this.form.id = data.id;
    },
    clickRename(data) {
      this.showRename = true;
      this.$set(this.form,'templateName','')
      this.form.id = data.id;
    },
    Rename() {
      if (!this.form.templateName) {
        this.$notify.error({
          title: this.$t('lang.SText142'),
          offset: 100,
          duration: 3000,
        });
        return;
      }
      let url = this.source.booking_api.updateTemplateName;
      this.loading = true;
      this.$ajax
        .cPost(url, this.form)
        .then((res) => {
          this.loading = false;
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
            });
            this.showRename = false;
            delete this.form.templateName;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
          this.query();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onloadSuccess(data) {
      this.listData = data.rows;
    },
    Delete() {
      let url = this.source.booking_api.deleteTemplate;
      this.loading = true;
      this.showDelect = false;
      this.$ajax
        .cPost(url, this.form)
        .then((res) => {
          this.loading = false;
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
            });
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
          this.query();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    Booking(row) {
      this.$router.push({ name: "Booking", params: { dOrTId: row.id } });
    },
    back() {
      this.$router.push("/Booking");
    },
    Cancel() {
      this.showDelect = false;
      this.showRename = false;
    },
    Save() {
      this.showDelect = false;
      this.showRename = false;
    },
    clickDraft() {
      this.$router.push({ path: "/Booking/Draft" });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.Template {
  padding-bottom: 100px;
  .FCL,
  .LCL {
    display: flex;
    align-items: center;
    .typeIcon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      fill: $mainColor;
      stroke: $mainColor;
    }
    .line {
      height: 15px;
      background: $mainColor;
      width: 1px;
      margin-right: 10px;
    }
  }

  .list-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .header-left {
      display: flex;
      color: $mainColor;
      font-size: 30px;
      .number {
        font-size: 20px;
        line-height: 35px;
        margin-left: 30px;
      }
    }
    .header-right {
      .el-button {
        height: 60px;
        font-size: 18px;
        color: $mainColor;
        border: 2px solid $mainColor;
        border-radius: 0;
        font-family: "montserratregular";
      }
      .el-button:hover {
        background-color: $background2;
        color: #fff;
        border: 2px solid $background2;
      }
      .create {
        background-color: $background2;
        color: #fff;
        border: 2px solid $background2;
        width: 210px;
        height: 60px;
        padding: 0;
        text-align: center;
      }
    }
  }

  .emptySlot {
    height: 40px;
    line-height: 40px;
    font-size: 30px;
    color: $borderColor1 !important;
  }

  .nameInput {
    margin: 20px 0;
  }
  .port {
    .svg-icon {
      color: $background2;
      height: 10px !important;
      width: 10px !important;
      margin-top: -5px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
</style>